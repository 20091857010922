import './nav';
import '../styles/styles.scss';
import '../img/images';

import $ from 'jquery';
import {getViewport} from "./utils";

$(document).ready(() => {
	// html
	const $html = $('html');

	$('a.js-scroll').on('click', function (e) {
		const $anchor = $(this);

		$('html, body').stop().animate({
			scrollTop: $($anchor.attr('href')).offset().top - 78
		}, 1000);
		e.preventDefault();
	});

	$('a.next-reg').on('click', e => {
		$('.mauticform-message').text("");

		e.preventDefault();
	});

	$(window).scroll(() => {
		if (( $(window).scrollTop() - $('body').offset().top ) > 0) {
			$('body').addClass("fixed-header");
		} else {
			$('body').removeClass("fixed-header");
		}
	});

	$('.sec-gmap .map').addClass('scrolloff').addClass('greyscale');                // set the mouse events to none when doc is ready

	$('.sec-gmap .map-wrap').on("mouseup", () => {          // lock it when mouse up
		$('.sec-gmap .map').addClass('scrolloff').addClass('greyscale');
		//somehow the mouseup event doesn't get call...
	});
	$('.sec-gmap .map-wrap').on("mousedown", () => {        // when mouse down, set the mouse events free
		$('.sec-gmap .map').removeClass('scrolloff').removeClass('greyscale');
	});

	$(".sec-gmap .map").mouseleave(() => {              // becuase the mouse up doesn't work...
		$('.sec-gmap .map').addClass('scrolloff').addClass('greyscale');            // set the pointer events to none when mouse leaves the map area
	                                            // or you can do it on some other event
	});

	$('#main-menu ul').onePageNav({
		currentClass: 'selected',
		scrollThreshold: 0.4,
		headerHeight: 56,

		begin: () => {
			//Hack so you can click other menu items after the initial click
			$('body').append('<div id="device-dummy" style="height: 1px;"></div>');
		},
		end: () => {
			$('#device-dummy').remove();
		}
	});
	// enable transition after all has been done
	$html.removeClass('disable-transition');

});

